import { GraphHighlightCoordsState, GraphHighlightFocusState } from 'Components/Graphs';
import { AppType } from 'Types/Auth.types';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { DateRangeValue } from 'Types/DateRange.types';
import { CommunicationTimelineParams } from 'Components/CommunicationTimeline';
import { ContentGenerationModalParams } from 'Components/ContentGeneration';
import { AddLifecycleEventParams, DeleteLifecycleEventCommunicationModalParams } from 'Components/CustomEvents';

export const AppTypeAtom = atom<AppType | null>(null);

export interface LayoutInfo {
  contentOffsetY: number;
}
export const LayoutAtom = atom<LayoutInfo>({ contentOffsetY: 0 });

export const DateRangeAtom = atom<DateRangeValue | null>(null);

export const SlidingDateRangeAtom = atom<DateRangeValue | null>(null);

export const CommunicationTimelineParamsAtom = atom<CommunicationTimelineParams | null>(null);

export const ReportCommunicationTimelineParamsAtom = atom<CommunicationTimelineParams | null>(null);

export const ContentGenerationModalParamsAtom = atom<ContentGenerationModalParams | null>(null);

export const FocusTableColumnIdAtom = atom<string | null>(null);

export const AddLifecycleEventAtom = atom<AddLifecycleEventParams | null>(null);

export const DeleteLifecycleEventCommunicationAtom = atom<DeleteLifecycleEventCommunicationModalParams | null>(null);

export const GraphHighlightCoordsAtomFamily = atomFamily(
  (param: { key: string; value?: GraphHighlightCoordsState | null }) =>
    atom<GraphHighlightCoordsState>(
      param.value ?? {
        chartDimentions: undefined,
        barsCoords: undefined,
        barWidth: undefined
      }
    ),
  (x, y) => x.key === y.key
);

export const GraphHighlightFocusAtomFamily = atomFamily(
  (param: { key: string; value?: GraphHighlightFocusState | null }) =>
    atom<GraphHighlightFocusState>(param.value ?? { weekInFocusIndex: null }),
  (x, y) => x.key === y.key
);
