import { useCallback, useEffect, useRef } from 'react';
import { Form, FormInstance } from 'Components/Primitives';
import { FormFieldMetadata, FormFieldType, RecursivePartial } from 'Components/FormGenerator/FormGenerator.types';
import { GridLayout } from 'Components/Styled/GridLayout';
import { FormGeneratorField } from 'Components/FormGenerator/FormGeneratorField';
import {
  FormInstanceWithNativeElement,
  useScrollToFirstErrorOnSubmit
} from 'Components/FormGenerator/hooks/useFormUtils';

export interface FormGeneratorProps<T> {
  id: string;
  metadata: FormFieldMetadata[];
  data?: RecursivePartial<T>;
  layout: 'horizontal' | 'vertical';
  form: FormInstance<T>;
  colGap?: number;
  className?: string;
  'data-testid'?: string;
  onValuesChange?(changedValues: Partial<T>, formData: T): void;
  onFinish?(formData: T): void;
}

export const FormGenerator = <T extends object>({
  id,
  metadata,
  data,
  layout,
  form,
  colGap,
  className,
  'data-testid': dataTestId,
  onFinish,
  onValuesChange
}: FormGeneratorProps<T>) => {
  const ref = useRef<FormInstanceWithNativeElement>(null);
  useScrollToFirstErrorOnSubmit(ref);

  const getFormItemClass = useCallback(
    (fmd: FormFieldMetadata): string => {
      if (fmd.colSize) {
        return fmd.colSize;
      }
      if (layout === 'vertical') {
        return 'col-12';
      }
      if (fmd.fieldType === FormFieldType.String && fmd.stringOptions?.mode === 'textArea') {
        return 'col-8';
      }
      return 'col-4';
    },
    [layout]
  );

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [form, data]);

  return (
    <Form
      id={id}
      form={form}
      ref={ref}
      layout={'vertical'}
      className={className}
      data-testid={dataTestId}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <GridLayout colgap={colGap} rowgap={0}>
        {metadata.map((fmd) => (
          <FormGeneratorField key={fmd.id} metadata={fmd} className={getFormItemClass(fmd)} />
        ))}
      </GridLayout>
    </Form>
  );
};
