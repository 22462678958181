import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { TableView } from 'Components/Table/Table.types';

export type TableData<T> = T[] | undefined;

export const TableViewAtomFam = atomFamily(
  (param: { key: string; value?: TableView | undefined }) => atom<TableView | undefined>(param.value ?? undefined),
  (x, y) => x.key === y.key
);

export const TableDataAtomFam = atomFamily(
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  (param: { key: string; value?: TableData<any> | undefined }) =>
    atom<TableData<any> | undefined>(param.value ?? undefined),
  (x, y) => x.key === y.key
);

export const TableColumnSelectAtomFam = atomFamily(
  (param: { key: string; value?: { ids: string[] } | undefined }) =>
    atom<{ ids: string[] } | undefined>(param.value ?? undefined),
  (x, y) => x.key === y.key
);

export const TableColumnOrderAtomFam = atomFamily(
  (param: { key: string; value?: { ids: string[] } | undefined }) =>
    atom<{ ids: string[] } | undefined>(param.value ?? undefined),
  (x, y) => x.key === y.key
);
