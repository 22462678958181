import { RefObject, useCallback, useEffect } from 'react';
import { FormInstance } from 'Components/Primitives';

export interface FormInstanceWithNativeElement extends FormInstance {
  nativeElement: HTMLElement;
}

const getScrollableEl = (node: HTMLElement | null): HTMLElement | null => {
  if (node == null) return null;
  return node.scrollHeight > node.clientHeight ? node : getScrollableEl(node.parentNode as HTMLElement | null);
};

export const useScrollToFirstErrorOnSubmit = (ref: RefObject<FormInstanceWithNativeElement | null>): void => {
  const onSubmitCapture = useCallback(() => {
    if (!ref.current) return;
    const formItems: HTMLElement[] = Array.from(ref.current.nativeElement.querySelectorAll('.ant-form-item'));

    ref.current
      .validateFields()
      .catch(() => {})
      .finally(() => {
        const errors = ref.current?.getFieldsError();

        const firstErrorIndex = errors?.findIndex((e) => e.errors.length > 0);
        if (typeof firstErrorIndex !== 'number' || firstErrorIndex === -1) return;
        const errorElement = formItems[firstErrorIndex];

        const parent = getScrollableEl(errorElement.parentNode as HTMLElement | null);
        parent?.scrollTo({ top: errorElement.offsetTop - 80 });
      });
  }, [ref]);

  useEffect(() => {
    if (!ref.current) return;
    const formEl = ref.current.nativeElement;
    formEl.addEventListener('submit', onSubmitCapture, true);
    return () => {
      formEl.removeEventListener('submit', onSubmitCapture, true);
    };
  }, [ref, onSubmitCapture]);
};
