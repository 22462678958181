import { AbsoluteDateRange } from 'Types/DateRange.types';
import {
  formatDistance,
  intlFormat,
  formatDistanceStrict,
  isSameDay,
  isValid,
  format,
  add,
  isBefore,
  sub,
  isAfter,
  differenceInMilliseconds,
  parse,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  endOfDay,
  differenceInDays,
  differenceInMonths,
  secondsToHours,
  differenceInSeconds,
  intervalToDuration,
  Duration
} from 'date-fns';
import { getUserLocale } from 'get-user-locale';
import { translate } from 'Utils/translate';

export interface FormatDateOptions {
  withTime?: boolean;
  useStrict?: boolean;
  formatStrictOptions?: FormatStrictOptions;
}

export interface FormatStrictOptions {
  addSuffix?: boolean;
  unit?: 'day';
}

const preferedLanguage = getUserLocale() || 'en-us';

export const isValidDate = isValid;

export const parseDate = parse;

export const getTimezoneString = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getLocalDate = (d: Date | string) => {
  return typeof d === 'string' ? (d.split('T').length === 2 ? new Date(d) : new Date(`${d}T00:00`)) : d;
};

export const formatDateByDateStr = (d: Date | string, formatStr: string): string => format(new Date(d), formatStr);

export const formatDate = (d: Date | string, showTime?: boolean): string =>
  intlFormat(
    getLocalDate(d),
    showTime
      ? {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }
      : {},
    { locale: preferedLanguage }
  );

export const formatTimeFromDate = (
  d: string,
  formatTimeOptions: {
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
  } = {
    hour: '2-digit',
    minute: '2-digit'
  }
): string => intlFormat(getLocalDate(d), formatTimeOptions, { locale: preferedLanguage });

export const formatDistanceFromSeconds = (
  seconds: number,
  options: { useStrict: boolean; addSuffix?: boolean } = { useStrict: true, addSuffix: false }
): string =>
  options.useStrict
    ? formatDistanceStrict(0, seconds * 1000, { addSuffix: options.addSuffix })
    : formatDistance(0, seconds * 1000, { includeSeconds: false, addSuffix: options.addSuffix });

export const formatDateByDistance = (d: string, options?: FormatDateOptions): string => {
  const date = new Date(d);
  const now = Date.now();

  if (options?.withTime) {
    return intlFormat(
      date,
      {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      },
      { locale: preferedLanguage }
    );
  }

  return Math.abs(differenceInDays(now, date)) > 2
    ? formatDate(d)
    : options?.useStrict
      ? isSameDay(now, date)
        ? translate('common:dates:today')
        : `${formatDistanceStrict(date, now, getFormatStrictOptions(options.formatStrictOptions))}`
      : `${formatDistance(date, now, { addSuffix: true })}`;
};

const DURATION_DAYS_THRESHOLD_DEFAULT = 2;
export const formatDurationFromSeconds = (d: number, options?: { durationDaysThreshold?: number }): string => {
  const duration = intervalToDuration({ start: 0, end: d * 1000 });
  return (duration.days || 0) > (options?.durationDaysThreshold || DURATION_DAYS_THRESHOLD_DEFAULT) - 1
    ? formatDistanceStrict(0, d * 1000, { unit: 'day' })
    : formatDistanceStrict(0, d * 1000, { unit: 'hour' });
};

export const getDaysPassed = (date: string): number => {
  const lastTouch = new Date(date).getTime();
  if (!lastTouch) {
    return 0;
  }
  return differenceInDays(Date.now(), lastTouch);
};

export const isInSelectedTimeRange = (rangeEnd: number, date: string): boolean => {
  const days = getDaysPassed(date);
  return days < rangeEnd;
};

export const getQueryStringDate = (d: Date): string => d.toISOString().split('T')[0];

export const formatDateAsQueryStringDate = (d: Date | string): string => format(new Date(d), 'yyyy-MM-dd');

export const getLatestDate = (dates: string[]) =>
  [...dates].sort((a: string, b: string) => (a && b && isBefore(new Date(a), new Date(b)) ? 1 : -1))[0];

export const getDateRange = (from: string, daysToAdd?: number): AbsoluteDateRange => {
  const day1 = getLocalDate(from);

  const day2 = add(day1, {
    days: daysToAdd ?? 7
  });

  const day1Format = format(day1, 'yyyy-MM-dd');
  const day2Format = format(day2, 'yyyy-MM-dd');

  return daysToAdd && daysToAdd < 0 ? [day2Format, day1Format] : [day1Format, day2Format];
};

export const isPastDate = (d: string): boolean | undefined => {
  const date = new Date(d);
  const now = Date.now();

  return isValidDate(date) ? isBefore(date, now) : undefined;
};

export type DateSplitToWords = { day: string; date: string; month: string; year: string };

export const getDateAsWords = (date: string): DateSplitToWords => {
  const dateAsWords = format(getLocalDate(date), 'EE-dd-MMM-yyyy').split('-');
  return {
    day: dateAsWords[0],
    date: dateAsWords[1],
    month: dateAsWords[2],
    year: dateAsWords[3]
  };
};

const getFormatStrictOptions = (options: FormatStrictOptions | undefined): FormatStrictOptions =>
  options
    ? {
        ...{ addSuffix: options.addSuffix || false },
        ...(options.unit && { unit: options.unit })
      }
    : {
        addSuffix: false
      };

export const getHoursFromSeconds = (seconds: number): number => Math.round(seconds / 60 / 60);

export const subtractTimeFromDate = (date: Date, duration: Duration) => sub(date, duration);

export const addTimeToDate = (date: Date, duration: Duration) => add(date, duration);

export const isDate1BeforeDate2 = (date1: Date, date2: Date): boolean => isBefore(date1, date2);

export const isDate1AfterDate2 = (date1: Date, date2: Date): boolean => isAfter(date1, date2);

export const getStartOfDay = (date: Date): Date => startOfDay(date);

export const getEndOfDay = (date: Date): Date => endOfDay(date);

export const getStartOfWeek = (date: Date): Date => startOfWeek(date);

export const getStartOfMonth = (date: Date): Date => startOfMonth(date);

export const getStartOfQuarter = (date: Date): Date => startOfQuarter(date);

export const getStartOfYear = (date: Date): Date => startOfYear(date);

export const getDifferenceInMS = (date1: Date, date2: Date): number => differenceInMilliseconds(date1, date2);

export const getDifferenceInSeconds = (date1: Date, date2: Date): number => differenceInSeconds(date1, date2);

export const getDifferenceInDays = (date1: Date, date2: Date): number => differenceInDays(date1, date2);

export const getDifferenceInMonths = (date1: Date, date2: Date): number => differenceInMonths(date1, date2);

export const getSecondsToHours = (v: number) => secondsToHours(v);
