import React from 'react';
import * as Sentry from '@sentry/react';
import { AuthProvider } from 'Providers/Authentication';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UiUtilsProvider } from 'Providers/UiUtils';
import { productionEnv } from 'Utils/environment';
import { ErrorFallback } from 'Views/ErrorPage';
import { lazyWithRetries } from 'Utils/lazyWithRetries';

const MobileApp = lazyWithRetries(() => import('Mobile/MobileApp/MobileApp'));

if (productionEnv) {
  Sentry.init({
    dsn: 'https://935eaba1e02f4ab380fe7b40a182fb16@o508855.ingest.sentry.io/5602205',
    integrations: [Sentry.browserTracingIntegration()],
    denyUrls: ['localhost'],
    tracesSampleRate: 1.0
  });
}

const queryClient = new QueryClient();

const MobileAppEntry: React.FC = () => (
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UiUtilsProvider>
          <MobileApp />
        </UiUtilsProvider>
      </AuthProvider>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

export default MobileAppEntry;
