import React from 'react';
import * as Sentry from '@sentry/react';
import { AuthProvider } from 'Providers/Authentication';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UiUtilsProvider } from 'Providers/UiUtils';
import { productionEnv } from 'Utils/environment';
import { ErrorFallback } from 'Views/ErrorPage';
import { lazyWithRetries } from 'Utils/lazyWithRetries';

if (productionEnv) {
  Sentry.init({
    dsn: 'https://935eaba1e02f4ab380fe7b40a182fb16@o508855.ingest.sentry.io/5602205',
    integrations: [Sentry.browserTracingIntegration()],
    denyUrls: ['localhost'],
    tracesSampleRate: 1.0
  });
}

const IframeApp = lazyWithRetries(() => import('Views/IframeApp/IframeApp'));
const queryClient = new QueryClient();

const IframeAppEntry: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UiUtilsProvider>
            <IframeApp />
          </UiUtilsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default IframeAppEntry;
