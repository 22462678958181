import React, { useEffect, useState } from 'react';
import { FilterMetadata } from 'Components/Filters/Filter.types';
import { DateRangeValue } from 'Types/DateRange.types';
import { useAtom } from 'jotai';
import { SlidingDateRangeAtom } from 'Atoms';
import { getDateRangeIsHistoricalWarning, getDateRangeText } from 'Components/DateRangeSelect';
import { Icon, Icons, Tooltip } from 'Components/Primitives';

interface FilterTagDateRangeProps {
  metadata: FilterMetadata;
  value: DateRangeValue;
  onDateRangeWarningChange?(shouldWarn: boolean): void;
}

const FilterTagDateRange: React.FC<FilterTagDateRangeProps> = ({ metadata, value, onDateRangeWarningChange }) => {
  const [displayVal, setDisplayVal] = useState<string>();
  const [warningText, setWarningText] = useState<string | null>(null);
  const [slidingDateRange] = useAtom(SlidingDateRangeAtom);

  useEffect(() => {
    setDisplayVal(getDateRangeText(value));
    setWarningText(getDateRangeIsHistoricalWarning(value));
  }, [value]);

  useEffect(() => {
    if (!slidingDateRange) return;
    setDisplayVal(getDateRangeText(slidingDateRange));
  }, [slidingDateRange]);

  useEffect(() => {
    onDateRangeWarningChange && onDateRangeWarningChange(!!warningText);
  }, [warningText, onDateRangeWarningChange]);

  return (
    <>
      {metadata.label}: {displayVal}
      {warningText && (
        <>
          {' '}
          <Tooltip title={warningText}>
            <Icon icon={Icons.Warning} />
          </Tooltip>
        </>
      )}
    </>
  );
};

export default FilterTagDateRange;
